export class ShopSignageViewModel {
  constructor(shopData, facilityData, isPublicResources) {
    this._shopData = shopData
    this._facilityData = facilityData
    this.isPublicResources = isPublicResources
    this._trustScoreData = null
    this.sensorData = null
    this.sensorMetaData = null
    this._shopConfirmListData = null
  }

  get shopData() {
    return this._shopData.certificateTable[0].certificateInfo
  }

  get facilityData() {
    return this._facilityData.certificateTable[0].certificateInfo
  }

  get shopConfirmListData() {
    return this._shopConfirmListData
  }

  set shopConfirmListData(shopConfirmListData) {
    this._shopConfirmListData = shopConfirmListData
  }

  get trustScoreData() {
    return this._trustScoreData
  }

  set trustScoreData(trustScoreData) {
    this._trustScoreData = trustScoreData
  }

  get shop() {
    const {
      shopName,
      shopNameEnglish,
      shopAddress,
      shopAddressEnglish,
      shopUrl,
      shopInfoUpdateDate,
      shopImageUrl,
      videoUrl,
    } = this.shopData.extendedItemsArea
    const {
      freeDescription,
      freeDescriptionEnglish,
    } = this.shopData.abilityInfo

    return {
      name: shopName,
      nameEnglish: shopNameEnglish,
      address: shopAddress,
      addressEnglish: shopAddressEnglish,
      siteUrl: shopUrl,
      comment: freeDescription,
      commentEnglish: freeDescriptionEnglish,
      thumbnail: shopImageUrl || '',
      youtubeVideoId: videoUrl || '',
      updatedAt: shopInfoUpdateDate,
    }
  }

  get facility() {
    const {
      facilityName,
      facilityNameEnglish,
    } = this.shopData.extendedItemsArea

    if (!facilityName && !facilityNameEnglish) {
      return null
    }

    return {
      name: facilityName,
      nameEnglish: facilityNameEnglish,
    }
  }

  get shopConfirmList() {
    return this.shopConfirmListData
      ? this.shopConfirmListData.shopConfirmList.map(
          ({ organization, organizationEnglish, shopConfirmUpdateDate }) => ({
            organization,
            organizationEnglish,
            confirmedAt: shopConfirmUpdateDate,
          })
        )
      : []
  }

  get trustIndex() {
    return this.trustScoreData ? this.trustScoreData.trustScore : 0
  }

  get measures() {
    const {
      ventilationRunning,
      disinfectShopFacility,
      disinfectShopTable,
      customerCisinfect,
      customerThermometry,
      tableDistance,
      staffThermometry,
      staffMask,
      disinfectRestroom,
      certifiedStoreMark,
    } = this.shopData.abilityInfo
    const {
      ventilationVolume,
      ventilationRunning: facilityVentilationRunning,
      disinfectRestroom: facilityDisinfectRestroom,
    } = this.facilityData.abilityInfo

    return {
      shop: {
        ventilation: {
          running: getMeasureStatus(ventilationRunning),
        },
        disinfect: {
          facility: getMeasureStatus(disinfectShopFacility),
          table: getMeasureStatus(disinfectShopTable),
        },
        customer: {
          disinfect: getMeasureStatus(customerCisinfect),
          thermometry: getMeasureStatus(customerThermometry),
        },
        distance: {
          table: getMeasureStatus(tableDistance),
        },
        staff: {
          healthManagement: getMeasureStatus(staffThermometry),
          wearingMask: getMeasureStatus(staffMask),
        },
        restroom: {
          disinfect: getMeasureStatus(disinfectRestroom),
        },
        certification: {
          mark: getMeasureStatus(certifiedStoreMark),
        },
      },
      facility: {
        ventilation: {
          volume: getMeasureStatus(ventilationVolume),
          running: getMeasureStatus(facilityVentilationRunning),
        },
        restroom: {
          disinfect: getMeasureStatus(facilityDisinfectRestroom),
        },
      },
    }
  }

  get sensor() {
    return this.sensorData && this.sensorMetaData
      ? {
          ...this.sensorMetaData,
          ...this.sensorData,
        }
      : null
  }
}

function getMeasureStatus(value) {
  return value === '1'
}
