<template>
  <SignagePresentation
    v-if="!isLoading"
    :key="presentationKey"
    :is-public-resources="isPublicResources"
    :shop="shop"
    :facility="facility"
    :measures="measures"
    :shop-confirm-list="shopConfirmList"
    :trust-index="trustIndex"
    :sensor="sensor"
    :updated-at="updatedAt"
  />
</template>

<script>
import SignagePresentation from '@/components/shop/signage/SignagePresentation.vue'
import { requestShopSignageData } from '@/assets/request/requestShopSignageData'
import { requestSensorData } from '@/assets/request/requestSensorData'
import { requestCanceler } from '@/assets/modules/request'
import dayjs from 'dayjs'
import { postAccessLog } from '@/assets/request/api/postAccessLog'
import { getAccessDecision } from '@/assets/request/api/getAccessDecision'

export default {
  name: 'ShopSignage',
  components: {
    SignagePresentation,
  },
  metaInfo() {
    return {
      title: this.shop === null ? '' : this.shopName,
      meta: [
        {
          name: 'viewport',
          vmid: 'viewport',
          content: `width=${this.width},user-scalable=no`,
        },
      ],
    }
  },
  data() {
    return {
      isLoading: true,
      isPublicResources: true,
      shop: null,
      facility: null,
      organization: null,
      measures: null,
      shopConfirmList: [],
      trustIndex: 0,
      sensor: null,
      updatedAt: null,
      timer: false,
    }
  },
  computed: {
    width() {
      switch (this.$route.query.type) {
        case 'mobile':
          return 375
        default:
          return 1080
      }
    },
    presentationKey() {
      return `${this.$route.params.portalId}-${this.$route.params.id}`
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
  },
  beforeRouteEnter(to, _from, next) {
    requestShopSignageData(to.params.id).then((response) => {
      if (response.isError) {
        next({ name: 'Error' })
        return
      }

      next((vm) => {
        vm.setData(response.data)
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === to.name && from.params.id === to.params.id) {
      next()
      return
    }

    requestCanceler.cancel()
    requestShopSignageData(to.params.id).then((response) => {
      if (response.isError) {
        next({ name: 'Error' })
        return
      }

      this.setData(response.data)
      next()
    })
  },
  beforeRouteLeave(_to, _from, next) {
    requestCanceler.cancel()
    if (this.timer !== false) {
      clearTimeout(this.timer)
    }
    next()
  },
  methods: {
    setData(vm) {
      this.shop = vm.shop
      this.facility = vm.facility
      this.measures = vm.measures
      this.shopConfirmList = vm.shopConfirmList
      this.trustIndex = vm.trustIndex
      this.updateUpdatedAt()
      this.sensor = vm.sensor
      this.isPublicResources = vm.isPublicResources
      this.isLoading = false

      this.$store.commit('detail/setHasEnglishPage', vm.hasEnglishPage)
      this.startUpdateSensorDataTimer()
    },
    startUpdateSensorDataTimer() {
      if (this.timer !== false) {
        clearTimeout(this.timer)
        this.timer = false
        requestCanceler.cancel()
      }

      if (!this.sensor) return

      this.timer = setTimeout(() => {
        requestSensorData(
          this.$route.params.id,
          this.sensor.sensorId,
          this.sensor.roomId
        ).then((response) => {
          if (response.isError) {
            return
          }
          this.updateUpdatedAt()
          this.sensor.dataList = response.data.sensorData
          this.timer = false

          this.startUpdateSensorDataTimer()
        })
      }, 120000) // 2分に1度更新
    },
    updateUpdatedAt() {
      this.updatedAt = dayjs(new Date()).second(0).millisecond(0).toISOString()
    },
  },
  async mounted(){
    const result_ad = await getAccessDecision(this.$route.params.id)
    if (result_ad.isError) {
      return
    } else {
      postAccessLog(result_ad.data.pageUrl, 'detail', location.href)
    }
  },
}
</script>
