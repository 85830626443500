<template>
  <ul v-if="videoId || thumbnail" class="p-signageThumbnail">
    <li
      v-if="videoId"
      class="p-signageThumbnail__item p-signageThumbnail__item--video"
    >
      <PYouTubePlayer :video-id="videoId" size="3p2" v-model="youtubePlayed" />
    </li>
    <li v-if="thumbnail" class="p-signageThumbnail__item">
      <CImage :src="thumbnail" size="3p2" contain />
    </li>
  </ul>
</template>

<script>
import PYouTubePlayer from '@/components/common/PYouTubePlayer.vue'
import CImage from '@/components/common/CImage.vue'

export default {
  name: 'PSignageThumbnail',
  components: {
    PYouTubePlayer,
    CImage,
  },
  props: {
    videoId: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
  },
  data() {
    return {
      youtubePlayed: false,
    }
  },
}
</script>
