import store from '@/store'
import { Response } from '../modules/request/Response'
import { getFacilityData } from './api/getFacilityData'
import { getSensorData } from './api/getSensorData'
import { getSensorList } from './api/getSensorList'
import { getShopData } from './api/getShopData'
import { getFacilityDataJson } from './json/getFacilityDataJson'
import { getTrustScore } from './api/getTrustScore'
import { getSensorDataJson } from './json/getSensorDataJson'
import { getSensorListJson } from './json/getSensorListJson'
import { getShopDataJson } from './json/getShopDataJson'
import { getTrustScoreJson } from './json/getTrustScoreJson'
import { ShopSignageViewModel } from './viewModel/ShopSignageViewModel'
import { getShopConfirmListJson } from './json/getShopConfirmListJson'
import { getAccessDecision } from './api/getAccessDecision'

export async function requestShopSignageData(id) {
  /** アクセス可否を確認する */
  const accessDecisionResponse = await getAccessDecision(id)
  if (accessDecisionResponse.isError) {
    // 取得できなければ404
    return new Response({
      status: 404,
    })
  }
  // URLのオリジナルIDを退避
  let org_id = id;
  // 取得しなおしたIDで置き換える
  id = accessDecisionResponse.data.pageUrl;
  
  const isLoggedIn = store.getters['auth/isLoggedIn']
  /** データの取得元がフリー公開かどうか */
  let isPublicResources = true

  let shopDataResponse = await getShopDataJson(id)

  // ログイン状態でトラスト証明書(店舗情報)JSONが取得できない場合はAPIでのリクエスト試行する
  if (isLoggedIn && shopDataResponse.isError) {
    shopDataResponse = await getShopData(id)
    isPublicResources = false
  }

  // トラスト証明書(店舗情報)が取得できなければ404
  if (shopDataResponse.isError) {
    return new Response({
      status: 404,
    })
  }

  // 最初に全てのリクエストを実行する
  /** トラスト証明書(施設情報)リクエスト */
  const facilityRequest = isPublicResources
    ? getFacilityDataJson(id)
    : getFacilityData(id)
  /** 第三者チェック情報リクエスト */
  const shopConfirmListRequest = isPublicResources
    ? getShopConfirmListJson(id)
    : null // 限定公開の場合はリクエストしない
  /** トラストスコア情報リクエスト */
  const trustScoreRequest = isPublicResources
    ? getTrustScoreJson(id)
    : getTrustScore(id)
  /** センサーリスト情報リクエスト */
  const sensorListRequest = isPublicResources
    ? getSensorListJson(id)
    : getSensorList(id)

  const facilityResponse = await facilityRequest
  /** viewModel */
  const shopData = new ShopSignageViewModel(
    shopDataResponse.data,
    facilityResponse.data,
    isPublicResources
  )

  // センサーリストのデータリクエストを作成する
  let mainSensorMetaData
  let sensorDataRequest = isPublicResources ? getSensorDataJson : getSensorData

  // センサーリストの取得を待機
  const sensorListResponse = await sensorListRequest

  if (!sensorListResponse.isError) {
    mainSensorMetaData = sensorListResponse.data.mainSensor
  }

  // メインセンサーデータがない場合はAPIリクエストを実行する
  if (isPublicResources && !mainSensorMetaData) {
    /** センサーリスト追加情報リクエスト */
    const sensorListExtraRequest =
      isLoggedIn && isPublicResources ? getSensorList(id) : null

    const sensorListExtraResponse = sensorListExtraRequest
      ? await sensorListExtraRequest
      : null

    if (sensorListExtraResponse && !sensorListExtraResponse.isError) {
      mainSensorMetaData = sensorListResponse.data.mainSensor
    }

    if (mainSensorMetaData) {
      sensorDataRequest = getSensorData
    }
  }

  shopData.sensorMetaData = mainSensorMetaData

  const shopConfirmListResponse = shopConfirmListRequest
    ? await shopConfirmListRequest
    : null
  const trustScoreResponse = await trustScoreRequest
  const mainSensorDataResponse =
    mainSensorMetaData && sensorDataRequest
      ? await sensorDataRequest(
          id,
          mainSensorMetaData.sensorId,
          mainSensorMetaData.roomId
        )
      : null

  if (shopConfirmListResponse && !shopConfirmListResponse.isError) {
    shopData.shopConfirmListData = shopConfirmListResponse.data
  }
  if (!trustScoreResponse.isError) {
    shopData.trustScoreData = trustScoreResponse.data
  }

  if (mainSensorDataResponse && !mainSensorDataResponse.isError) {
    shopData.sensorData = {
      sensorId: mainSensorDataResponse.data.sensorId,
      roomId: mainSensorDataResponse.data.roomId,
      dataList: mainSensorDataResponse.data.sensorData,
    }
  }

  return new Response({
    status: 200,
    data: shopData,
  })
}
