<template>
  <div class="p-signage">
    <div class="p-signageInfo p-signage__info">
      <div class="l-signageContainer">
        <div class="p-signageHeader">
          <app-link :to="{ name: 'Index' }">
            <PSiteLogo class="p-signageHeader__logo" />
          </app-link>
          <app-link
            class="p-signageHeader__item"
            href="https://www.hitachi.co.jp/"
            target="_blank"
          >
            <img
              class="p-signageHeader__item__companyLogo"
              src="/img/common/hitachi_logo.png"
              alt=""
              width=""
            />
          </app-link>
        </div>
        <PSignageName
          class="p-signageInfo__name"
          :shop-name="shopName"
          :badges="badges"
        />
        <div class="p-signageInfo__data">
          <PSignageTrustIndex
            class="p-signageInfo__trustIndex"
            :measures="measures"
            :trust-index="trustIndex"
          />
          <PSignageThumbnail
            :thumbnail="shop.thumbnail"
            :video-id="shop.youtubeVideoId"
          />
          <PSignageQR class="p-signageInfo__qr" :qr-string="detailUrl" />
        </div>
      </div>
    </div>
    <div class="p-signageDetail p-signage__detail">
      <div class="l-signageContainer">
        <PSignageRangeSwitch
          v-if="isSensorShown"
          class="p-signageDetail__rangeSwitch"
          v-model="selectedRange"
        />
        <i18n
          tag="p"
          class="p-signageDetail__updatedAt"
          path="データ取得日時：{text}"
        >
          <template #text>{{
            (dataLastDate && $options.filters.dayjsFormat(dataLastDate)) || '-'
          }}</template>
        </i18n>
        <div class="p-signageDetail__iotStatus">
          <ul class="p-signageDetail__grid">
            <li
              v-for="item in computedDataList"
              :key="item.label"
              class="p-signageDetail__item"
            >
              <PSignageGraph
                :data="item"
                :rendered-at="updatedAt"
                :range="selectedRange"
              />
            </li>
            <li class="p-signageDetail__item p-signageDetail__item--qr">
              <PSignageQR :qr-string="detailUrl" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PSiteLogo from '@/components/common/PSiteLogo.vue'
import PSignageName from '@/components/shop/signage/PSignageName.vue'
import PSignageThumbnail from '@/components/shop/signage/PSignageThumbnail.vue'
import PSignageTrustIndex from '@/components/shop/signage/PSignageTrustIndex.vue'
import PSignageRangeSwitch from '@/components/shop/signage/PSignageRangeSwitch.vue'
import PSignageGraph from '@/components/shop/signage/PSignageGraph.vue'
import PSignageQR from '@/components/shop/signage/PSignageQR.vue'

export default {
  name: 'PSignagePresentation',
  components: {
    PSiteLogo,
    PSignageName,
    PSignageThumbnail,
    PSignageTrustIndex,
    PSignageRangeSwitch,
    PSignageGraph,
    PSignageQR,
  },
  props: {
    isPublicResources: {
      type: Boolean,
      default: true,
    },
    shop: {
      type: Object,
      default: null,
    },
    measures: {
      type: Object,
      default: null,
    },
    shopConfirmList: {
      type: Array,
      required: true,
      default: () => [],
    },
    trustIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    sensor: {
      type: Object,
      default: null,
    },
    updatedAt: {
      type: String,
      default: '',
    },
  },
  metaInfo() {
    return {
      htmlAttrs: {
        'data-type': this.dataType,
      },
    }
  },
  data() {
    return {
      selectedRange: '1day',
    }
  },
  computed: {
    dataType() {
      switch (this.$route.query.type) {
        case 'mobile':
          return 'mobile'
        default:
          return 'tablet'
      }
    },
    dataLastDate() {
      return (
        this.isSensorShown &&
        this.sensor.dataList[0].latestStatus.original.timestamp
      )
    },
    backLinkTo() {
      return {
        name: 'ShopDetail',
        params: { portalId: this.portalId, id: this.$route.params.id },
      }
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
    sensorName() {
      let sensorName
      switch (this.$i18n.locale) {
        case 'en':
          sensorName = this.sensor.sensorNameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return sensorName || this.sensor.sensorName
    },
    badges() {
      const result = []

      if (this.isSensorShown) {
        result.push('iotSensor')
      }
      if (this.shopConfirmList.length) {
        result.push('shopConfirm')
      }

      return result
    },
    isSensorShown() {
      return this.sensor !== null
    },
    sensorDataList() {
      return (this.sensor && this.sensor.dataList) || []
    },
    computedDataList() {
      return this.sensorDataList.filter((data) => data.dataList)
    },
    detailUrl() {
      return `${location.origin}/#/${this.portalId}/${this.$route.params.id}`
    },
  },
}
</script>
