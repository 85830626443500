<script>
import { mergeData } from 'vue-functional-data-merge'

const Badge = {
  iotSensor: {
    src: require('@/assets/img/icon/shop_badge/ico_iot_sensor.svg'),
  },
  shopConfirm: {
    src: require('@/assets/img/icon/shop_badge/ico_shop_confirm.svg'),
  },
}

export const BadgeKeys = Object.keys(Badge)

export default {
  functional: true,
  name: 'PSignageBadge',
  props: {
    badge: {
      type: String,
      default: 'iotSensor',
      validator: (val) => BadgeKeys.includes(val),
    },
    alt: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data }) {
    const tag = data.tag || 'span'

    const computedClass = ['p-signageBadge', 'p-signageBadge--' + props.badge]

    if (props.large) computedClass.push('p-signageBadge--large')

    const componentData = mergeData(data, {
      class: computedClass,
    })

    const { src } = Badge[props.badge]

    return h(tag, componentData, [
      h('img', {
        attrs: { alt: props.alt, src },
      }),
    ])
  },
}
</script>
